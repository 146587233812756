import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive.js";
import Pagination from "../elements/common/Pagination";
import FooterTwo from "../component/footer/FooterTwo";
import ReactTooltip from 'react-tooltip';

class News2 extends Component{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    constructor () {
        super()
        this.state = {
          isOpen: false,
          currentPage: 3
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='News' />
                {/* End Pagehelmet  */}

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'News'}   />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">



                                        {/* Start Single Area */}


                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <a href="https://www.amazon.com/Adventures-Narrative-Gardener-Creating-Landscape/dp/1911282743" target="_blank"  rel="noopener noreferrer">
                                                    <div className="photoHover">
                                                    <img className="w-100" src="/assets/images/news/news-02.jpg"  rel="noopener noreferrer" alt="Adventures of A Narrative Gardener Book Cover" />
                                                    </div>
                                                    </a>

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--3 mt_sm--30">
                                                    <h4 className="title" data-tip data-for="amazon"><a href="https://www.amazon.com/Adventures-Narrative-Gardener-Creating-Landscape/dp/1911282743" target="_blank"  rel="noopener noreferrer">The Adventures of a Narrative Gardener: Creating a Landscape of Memory</a>
                                                    <ReactTooltip id="amazon" place="bottom" effect="solid">
                                                        <img height='160' width='240' src='assets/images/amazon.png' alt="Adventures of A Narrative Gardener Book Cover" />
                                                    </ReactTooltip>
                                                    </h4>
                                                    <h3 className="title" style ={{fontFamily: 'raleway, sans-serif'}}>September 1st, 2020</h3>
                                                    <p>This is an entirely new kind of garden book, rather than a homage to contemporary gardens, a survey of historic gardens or 
                                                    a "how-to" manual. Through a careful mix of rich visual imagery and memoir, author Ronald Lee Fleming brings to life the garden 
                                                    he has created at Bellevue House, Newport, and explains his many sources, many of which hold deeply personal memories.
                                                    </p>
                                                    <p>Available November 3rd, 2020</p>
                                                    <p><a href="https://gilesltd.com/" target="_blank"  rel="noopener noreferrer" style={{fontSize: '18px'}}>Published By GILES Ltd.</a></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="divider" style={{width: '90%', marginLeft: '5%', marginBottom: '50px', borderBottomStyle: 'solid', borderColor: '#661B0B'}}>
                                        </div>
                                        {/* End Single Area */}


                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Place Making Institute: Report from the First Institute, at Bellevue House, Newport</h4>
                                                    <h3 className="title" style={{fontFamily: 'raleway, sans-serif'}}>June 21-23rd, 2019</h3>
                                                    <p>Ronald Lee Fleming hosted the first Place Making Institute at the new library on
                                                            the grounds of Bellevue House. The communities described below in successive order
                                                            include Newport, Rhode Island, Oxford, Mississippi, New Harmony, Indiana, and
                                                            Cumberland, Rhode Island. The program showcased specific issues and solutions in
                                                            each community and brought together community planning directors with
                                                            distinguished faculty. Local planning and zoning boards often struggle to secure
                                                            higher standards from developers. They face the challenge of development which
                                                            diminishes local character.</p>
                                                    <p>Mr. Fleming evoked the words of Frederick Douglass when convening the group at
                                                        the new library: "Power concedes nothing without a demand. It never did, and it
                                                        never will." The assembled planners noted their intent is to use national publicity to
                                                        shine a light on these conditions, and to restore a 'working culture' which Fleming
                                                        defined as a "sense of mutual responsibility and accountability between different
                                                        centers of power.” The consensus encouraged free and unconstrained civic
                                                        conversation which could support effective planning and regulation.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/news/news-03.jpg" alt="Place Making Meeting Faculty"/>
                                                    Photo By: Mick Hales
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="row mt--20">
                            <div className="col-lg-12">
                                {/* Start Pagination Area */}
                                <Pagination number1={false}  number2={false} number3={true} />
                                {/* End Pagination Area */}
                            </div>
                        </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default News2;
