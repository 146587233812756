import React, { Component } from "react";
import { FiX , FiMenu } from "react-icons/fi";
import ReactTooltip from 'react-tooltip';
import { HashLink as Link } from 'react-router-hash-link';


class HeaderFive extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render(){
        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        const { logo, color , headerPosition } = this.props;
        let logoUrl;
            logoUrl = <img src="/assets/images/logo/townscapelogo2.jpg" alt="Townscape logo" />;


        return(
            <header className={`header-area formobile-menu ${headerPosition} ${color}`} >
                <div className="header-wrapper" id="header-wrapper" >
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-sm-6" style={{width: '80%'}}>
                                <div className="header-left" style={{ textAlign: 'left', float: 'left'}}>
                                    <div className="logo">
                                        <a href="/">
                                            {logoUrl} <strong style={{paddingLeft: 10, display: "inline-block", fontWeight: 600, fontFamily: 'Raleway'}} ><em>The Place Making Institute</em></strong>
                                        </a>
                                    </div>

                                </div>
                            </div>

                            <div className="col-sm-6 " style={{ marginTop: '40px', float: 'right', width: '20%'}}>
                                <div className="header-right justify-content-end">
                                    <nav className="mainmenunav d-lg-block">
                                        <ul className="mainmenu">
                                          <li><Link to="/" >Home</Link></li>
                                          <li><Link to="/news" >News</Link></li>
                                          <li><Link to="/map" >Map</Link></li>
                                          <li className="has-droupdown"><Link to="/about" >About</Link>
                                            <ul className="submenu">
                                                <li><Link to="/about#firstAnchor">The Place Making Institute</Link></li>
                                                <li><Link to="/about#secondAnchor">Alliance of Cultural Towns</Link></li>
                                                <li><Link to="/about#secondAnchor">Fellowships</Link></li>
                                                <li><Link to="/about#thirdAnchor">Site Plan</Link></li>
                                                <li><Link to="/about#thirdAnchor">Conference Space</Link></li>
                                                <li><Link to="/about#fourthAnchor">Faculty</Link></li>
                                            </ul>
                                          </li>
                                          <li className="has-droupdown"><Link to="/bellevuehouse" >Bellevue House</Link>
                                          <ul className="submenu">
                                                <li><Link to="/bellevuehouse#section1">Bellevue House</Link></li>
                                                <li><Link to="/bellevuehouse#section2">Interactive Site Plan</Link></li>
                                                <li><Link to="/bellevuehouse#section3">Gallery</Link></li>
                                            </ul>
                                          </li>
                                          <li><Link to="/contact" >Contact</Link></li>
                                        </ul>
                                    </nav>
                                    <div className="header-btn" data-tip data-for="amazon" style={{}}>
                                        <a className="rn-btn" target="_blank" href="https://www.amazon.com/Adventures-Narrative-Gardener-Creating-Landscape/dp/1911282743"  rel="noopener noreferrer">
                                            <span>Purchase<i> "The Adventures of a Narrative Gardener"</i></span>
                                        </a>
                                        <ReactTooltip id="amazon" place="bottom" effect="solid">
                                            <img height='160' width='240' src='assets/images/amazon.png' alt="Adventures of A Narrative Gardner Book Cover"/>
                                        </ReactTooltip>
                                    </div>
                                    {/* Start Humberger Menu  */}
                                    <div className="humberger-menu d-block d-lg-none pl--20" >
                                        <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                                    </div>
                                    {/* End Humberger Menu  */}
                                    <div className="close-menu d-block d-lg-none">
                                        <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderFive;
