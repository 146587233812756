import React, { Component } from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive.js";
import Footer from "../component/footer/FooterTwo";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import ImageMapper from 'react-image-mapper';

const TabOne = [
    {
        image: '01',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-01.jpg',
        category: 'Photo By: Mick Hales',
        title: 'View from the Oriental Vale to the Chinese Chippendale bridge and McIntire-inspired cupola.'
    },
    {
        image: '02',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-02.jpg',
        category: 'Photo By: Robert Kranz',
        title: 'Original gazebo built into the north wall of the Yew Garden, with the Pomona Bronze framed in boxwood hedges in the foreground.'
    },
    {
        image: '05',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-05.jpg',
        category: 'Photo By: Robert Kranz',
        title: 'South elevation of Bellevue House, designed by Ogden Codman, Jr. in 1910. '
    },
    {
        image: '15',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-15.jpg',
        category: 'Photo By: Warren Jagger',
        title: 'Exterior of Greenhouse and Stable Block.'
    },
    {
        image: '08',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-08.jpg',
        category: 'Photo By: Mick Hales',
        title: 'Detail of the Oriental Vale Garden at the Place Making Institute.'
    },
    {
        image: '11',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-11.jpg',
        category: 'Photo By: Mick Hales',
        title: 'Looking toward the formal French Garden and exedra.'
    },
    {
        image: '09',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Photo By: Mick Hales',
        title: 'Interior of the nymphaeum presentation space.'
    },
    {
        image: '10',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-10.jpg',
        category: 'Photo By: Mick Hales',
        title: 'Detail of grotto in the nymphaeum.'
    },
]
const TabTwo = [
    {
        image: '30',
        letter: 'A',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-30.jpg',
    },
    {
        image: '31',
        letter: 'B',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-03.jpg',
    },
    {
        image: '32',
        letter: 'C',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-32.jpg',
    },
    {
        image: '33',
        letter: 'D',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-33.jpg',
    },
    {
        image: '34',
        letter: 'E',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-34.jpg',
    },
    {
        image: '35',
        letter: 'F',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-35.jpg',
    },
    {
        image: '36',
        letter: 'G',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-36.jpg',
    },
    {
        image: '37',
        letter: 'H',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-37.jpg',
    },
    {
        image: '38',
        letter: 'I',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-38.jpg',
    },
    {
        image: '39',
        letter: 'J',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-39.jpg',
    },
    {
        image: '40',
        letter: 'K',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-40.jpg',
    },
    {
        image: '41',
        letter: 'L',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-41.jpg',
    },
    {
        image: '42',
        letter: 'M Alt',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-42.jpg',
    },
    {
        image: '43',
        letter: 'N',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-43.jpg',
    },
    {
        image: '44',
        letter: 'P',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-44.jpg',
    },
    {
        image: '45',
        letter: 'R',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-45.jpg',
    },
    {
        image: '46',
        letter: 'T',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-46.jpg',
    },
     {
        image: '47',
        letter: 'U',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-47.jpg',
    },
    {
        image: '48',
        letter: 'V',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-48.jpg',
    },
    {
        image: '49',
        letter: 'W',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-49.jpg',
    },
    {
        image: '50',
        letter: 'X',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-50.jpg',
    },
    {
        image: '51',
        letter: 'Y',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-51.jpg',
    },
    {
        image: '52',
        letter: 'M',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-52.jpg',
    },

]
const MAP = {
    name: "my-map",
    areas: [
        {
            name: "5",
            letter: "F",
            shape: "poly",
            coords: [ 360, 165, 360, 230, 417, 230, 422, 222, 436, 222, 436, 181],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "0",
            letter: "A",
            shape: "poly",
            coords: [350, 233, 350, 245, 347, 245, 347, 275, 340, 275, 340, 302, 347, 302, 347, 331, 350, 331, 350, 345, 378, 345, 
            378, 354, 388, 361, 400, 361, 414, 354, 414, 345, 442, 345, 442, 333, 444, 333, 444, 245, 440, 245, 440, 230,
            436, 222, 424, 222, 417, 233],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "8",
            letter: "I",
            shape: "poly",
            coords: [437, 182, 437, 225, 440, 230, 440, 245, 443, 245, 443, 331, 441, 331, 441, 343, 460, 343, 464, 350, 466, 353, 468, 356, 470, 359, 472, 360, 474, 360, 476, 360, 
            482, 357, 484, 354, 486, 351, 488, 348, 489, 346, 490, 343, 510, 343, 510, 197 ],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "14",
            letter: "P",
            shape: "poly",
            coords: [609, 219, 608, 230, 612, 235, 619, 236, 626, 233, 628, 222 ],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "6",
            letter: "G",
            shape: "poly",
            coords: [600, 290, 613, 278, 626, 292, 611, 303],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "2",
            letter: "C",
            shape: "poly",
            coords: [592, 387, 593, 360, 620, 361, 619, 389],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "19",
            shape: "poly",
            coords: [205, 420, 205, 510, 210, 510, 215, 509, 220, 509, 225, 508, 230, 507, 235, 506, 240, 504, 245, 501, 
            250, 497, 253, 492, 258, 483, 259, 475, 260, 465, 258, 455, 256, 450, 253, 445, 249, 437, 243, 430, 239, 427, 225, 422, 215, 420, 210, 420],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "3",
            shape: "poly",
            coords: [517, 500, 513, 547, 526, 550, 530, 501],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "4",
            shape: "poly",
            coords: [509, 575, 507, 595, 526, 596, 528, 577],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "13",
            shape: "poly",
            coords: [537, 520, 537, 550, 570, 550, 570, 520],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "17",
            shape: "circle",
            coords: [473, 580, 10],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "15",
            shape: "poly",
            coords: [442, 484, 442, 590, 495, 600, 503, 555, 503, 484],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "11",
            shape: "poly",
            coords: [584, 618, 594, 620, 602, 498, 611, 490, 612, 480, 604, 470, 609, 389, 600, 389, 594, 470, 
            587, 477, 586, 489, 593, 497],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "21",
            shape: "poly",
            coords: [645, 630, 645, 560, 712, 560, 710, 643],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "10",
            shape: "poly",
            coords: [852, 602, 853, 596, 879, 601, 878, 607],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "9",
            shape: "poly",
            coords: [879, 674, 879, 660, 878, 655, 876, 650, 874, 645, 870, 642, 865, 641, 860, 639, 850, 635, 845, 632, 843, 628, 
            841, 622, 840, 614, 840, 606, 841, 600, 843, 595, 850, 590, 860, 588, 865, 586, 870, 580, 871, 575, 870, 570, 868, 565,
            865, 560, 862, 555, 862, 553, 863, 549, 864, 545, 866, 544, 870, 541, 874, 540, 878, 541, 880, 543, 884, 545, 889, 547,
            894, 549, 899, 550, 905, 551, 908, 550, 912, 549, 925, 541, 966, 541, 968, 690],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "16",
            shape: "poly",
            coords: [892, 455, 894, 363, 961, 365, 962, 458],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "7",
            shape: "poly",
            coords: [880, 507, 880, 488, 895, 474, 912, 474, 928, 490, 927, 508, 911, 522, 893, 521],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "12",
            shape: "poly",
            coords: [900, 362, 900, 342, 902, 344, 905, 346, 908, 346, 913, 345, 916, 342, 917, 340,
            917, 362],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "20",
            shape: "poly",
            coords: [891, 336, 891, 319, 883, 319, 879, 312, 879, 306, 885, 300, 892, 300, 892, 295,  
            926, 295, 926, 338, 918, 338, 916, 340, 915, 342, 913, 344, 911, 345, 910, 345, 907, 344,
            905, 344, 904, 343, 902, 341,
            898, 336],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "1",
            shape: "poly",
            coords: [819, 325, 819, 305, 817, 305, 818, 278, 855, 279, 855, 306, 853, 306, 853, 325],
            preFillColor: "rgba(102, 27, 11, 0.0)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "18",
            shape: "poly",
            coords: [745, 340, 747, 311, 787, 312, 786, 340],
            preFillColor: "rgba(102, 27, 11, 0.1)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
        {
            name: "22",
            shape: "poly",
            coords: [382, 572, 382, 559, 392, 549, 401, 549, 410, 557, 410, 573],
            preFillColor: "rgba(102, 27, 11, 0.1)",
            fillColor: "rgba(102, 27, 11, 0.4)",
            strokeColor: "#000000",
            lineWidth: .8
            
        },
    ]
};

const URL = "/assets/images/portfolio/big/dp-big--portfolio-14.jpg";


class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            isOpen: false,
            tab2: 0,
            isOpen2: false,
        };
    }

    //Detect Window Resize

    state = {
    windowHeight: undefined,
    windowWidth: undefined
    }

    handleResize = () => this.setState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth * .9
    });

    componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
      }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
      }

    //Interactive Map Detect

    getInitialState() {
        return { hoveredArea: null, msg: null, moveMsg: null };
    }
    load() {
        this.setState({ msg: "Interact with image !" });
    }
    clicked(area, tab2) {
        this.setState({
            tab2: area.name,
            isOpen2: true,

        });
        console.log(area.name, tab2)
    }
    clickedOutside(evt) {
        const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
        this.setState({
            msg: `You clicked on the image at coords ${JSON.stringify(coords)} !`
        });
    }
    moveOnImage(evt) {
        const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
        this.setState({
            moveMsg: `You moved on the image at coords ${JSON.stringify(coords)} !`
        });
    }
    enterArea(area) {
        this.setState({
            hoveredArea: area,
            msg: `You entered ${area.shape} ${area.name} at coords ${JSON.stringify(
                area.name
            )} !`
        });
    }
    leaveArea(area) {
        this.setState({
            hoveredArea: null,
            msg: `You leaved ${area.shape} ${area.name} at coords ${JSON.stringify(
                area.coords
            )} !`
        });
    }
    moveOnArea(area, evt) {
        const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
        this.setState({
            moveMsg: `You moved on ${area.shape} ${
                area.name
            } at coords ${JSON.stringify(coords)} !`
        });
    }

    getTipPosition(area) {
        return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };

    }

    onImageLoadError(imageSrc, _srcType, errorEvent) {
    console.error(`Could not load image at area`, errorEvent); // eslint-disable-line no-console\
    
  }


    render() {
        const { tab1, isOpen, tab2, isOpen2 } = this.state;
        return (
            <div>

            <PageHelmet pageTitle='Bellevue House and Gardens' />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Bellevue House and Gardens'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                 {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120 bg_color--1">
                    <div className="container" id="section1">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h4 className="tilte">The Bellevue House and Gardens</h4>
                                    <p>Bellevue House & Gardens in Newport, RI encompasses history and memory, inspiration and motivation, demonstrating a wide range of influences from Mughal fountains and Italian grotesques to English stately homes and Japanese gardens. This narrative garden, actually a series of twelve gardens, speaks to us about history and context, in a conversation that conjures up a landscape of place memory; they resonate with our past experiences and evoke memories of other garden places familiar to thee seasoned garden visitor. Some thirty-five artists and artisans were commissioned to celebrate aspects of garden making and explore some larger questions regarding the integration of art and architecture at this historic property located on Bellevue Avenue. </p>
                                    <p>The early-twentieth-century house and gardens include the Federal-revival house designed by Ogden Codman, Jr. for his cousin Martha Codman in 1910, as well as grounds that were partially configured by the French landscape architect Achille Duchene - including large specimen trees and a two-story McIntire-style tea house that laid the ground work for further interpretation. Codman was co-author, with writer and designer Edith Wharton, of one of the first books on interior design in America, <em>The Decoration of Houses</em> (first published in 1897 and still in print today). </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Columns Area  */}

                {/* Start Image Area  */}
                <div className="row" style={{marginTop: '10px'}}>
                            <div className="col-lg-12 bg_color--5">
                                <div className="section-title service-style--3 text-center mb--25" style={{marginTop: '20px'}} id="section2">

                                    <h2 className="title"><em>Site Plan</em></h2>
                                    <p style={{fontSize: '1.5em', margin: '25px'}}>The PMI is located on the grounds of Bellevue House as illustrated in 
                                            this traditional style site plan, like a Sanborn map, by architect J.P. Couture of Providence. 
                                            Explore the interactive Bellevue House site plan by clicking the outlined areas below.</p>
                                    <div className="grid" style={{}}>
                                        <div className="presenter">
                                            <div style={{position: "relative", width: '92%', marginLeft: '4%'}}>

                                                <ImageMapper
                                                    src={URL}
                                                    map={MAP}
                                                    width={this.state.windowWidth}
                                                    imgWidth={1200}
                                                    onLoad={() => this.load()}
                                                    onClick={area => this.clicked(area)}
                                                    onMouseEnter={area => this.enterArea(area)}
                                                    onMouseLeave={area => this.leaveArea(area)}
                                                    onMouseMove={(area, _, evt) => this.moveOnArea(area, evt)}
                                                    onImageClick={evt => this.clickedOutside(evt)}
                                                    onImageMouseMove={evt => this.moveOnImage(evt)}
                                                    lineWidth={4}
                                                    strokeColor={"white"}
                                                    style={{borderStyle: 'solid', textAlign: 'center'}}
                                                />
                                                {this.state.hoveredArea && (
                                                    <span
                                                        className="tooltip"
                                                        style={{ ...this.getTipPosition(this.state.hoveredArea) }}
                                                    >
                                                        {this.state.hoveredArea && this.state.hoveredArea.name}
                                                    </span>
                                                )}
                                            </div>
                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>            
                {/* End Image Area  */}


                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="single-column">
                                    <h4 className="tilte">Place Making Institute Involvement</h4>
                                    <p>The Place Making Institute will be situated in the northeast quadrant of the Bellevue House grounds, where an “academical village,” a cluster of small buildings, are sited around a lawn, based on the one Thomas Jefferson developed at the University of Virginia. The village hosts community organizations for conferences and other exchanges of knowledge, it is comprised of a stable block, library, greenhouse, nymphaeum, rotunda, and artist-in-residence cabanas.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 mt_sm--30">
                                <div className="single-column">
                                    <h4 className="tilte">Bellevue House and Gardens Events</h4>
                                    <p>For most visitors, interaction will be through dance and music - festivals with lithe figures unraveling as they descend from trees, diving and writhing in our fountains, or perhaps dancing in a great tent on the lawn, attending a premier musical performance around the moss garden, or listening to a brass quintet by the rotunda. The commons at Bellevue House is intermittently occupied by community organizations: a dance company synchronizing those watery dives in the pools, a land trust, numerous garden clubs, hereditary societies, private social clubs, high school students performing scenes from Shakespearean plays, food and wine societies, daffodil lovers, beauty queen contestants, antique dealers, polo players, dance directors, associations of planners, and architectural historians. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Columns Area  */}

                <div className="divider" style={{
                    width: '90%', marginLeft: '5%', marginBottom: '50px', 
                    borderBottomStyle: 'solid', borderColor: '#661B0B', }}>
                </div>

                {/* Start Site Plan LightBox Area */}
                <div >
                    {isOpen2 && (
                        <Lightbox
                            mainSrc={TabTwo[tab2].bigImage}
                            nextSrc={TabTwo[(tab2 + 1) % TabTwo.length]}
                            prevSrc={TabTwo[(tab2 + TabTwo.length - 1) % TabTwo.length]}
                            imageLoadErrorMessage = {<div></div>}
                            enableZoom= {false}
                            onCloseRequest={() => this.setState({ isOpen2: false })}
                            onMovePrevRequest={() =>
                            this.setState({
                                tab2: (tab2 + TabTwo.length - 1) % TabTwo.length,
                            })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    tab2: (tab2 + 1) % TabTwo.length,
                                })
                            }
                        />
                    )}
                </div>
                {/* End Site Plan LightBox Area */}
                         
                {/* Start Portfolio Area  */}
                <div className="rn-portfolio-area ptb--120 bg_color--1 line-separator">
                    <div className="container" id="section3">
                        <div className="row">
                            {TabOne.map((value , index) => (
                                <div className="col-lg-6" key={index} >
                                    {isOpen && (
                                        <Lightbox
                                            mainSrc={TabOne[tab1].bigImage}
                                            nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                                            prevSrc={TabOne[(tab1 + TabOne.length - 1) % TabOne.length]}
                                            imageLoadErrorMessage = {<div></div>}
                                            enableZoom= {false}   
                                            onImageLoadError={() => this.onImageLoadError()}                        
                                            onCloseRequest={() => this.setState({ isOpen: false })}
                                            onMovePrevRequest={() =>
                                            this.setState({
                                                tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                                            })
                                            }
                                            onMoveNextRequest={() =>
                                                this.setState({
                                                    tab1: (tab1 + 1) % TabOne.length,
                                                })
                                            }
                                        />
                                    )}
                                    <div className="item-portfolio-static">
                                        <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                            <div className="portfolio-static">
                                                <div className="thumbnail-inner">
                                                    <div className="thumbnail">
                                                        <a href="#portfolio-details">
                                                            <img src={`/assets/images/portfolio/dp-portfolio-${value.image}.jpg`} alt="Portfolio Images"/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <div className="inner">
                                                        <p>{value.category}</p>
                                                        <h4><a href="#portfolio-details">{value.title}</a></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area  */}



            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}                
            </div>
        )
    }
}


export default Gallery;