import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive.js";
import FooterTwo from "../component/footer/FooterTwo";
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


const TabOne = [
    {
        image: '14',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-14.jpg',
       
    },

]

class About extends Component{

    setIndex = (selectedIndex) => {
    this.setState({index: selectedIndex})
    }   

    setDirection = (direction) => {
        this.setState({direction: direction})
    }

    handleSelect = (selectedIndex, e) => {
        this.setIndex(selectedIndex);
       
    }

    state = { isOpen: false };

    handleShowDialog = () => {
    this.setState({ isOpen: !this.state.isOpen });
    console.log('cliked');
    };

    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            setIndex: 0,
            direction: null,
            setDirection: null,
            tab1: 0,
            isOpen: false,
        };
    }
  
    render(){
        const { index, direction, tab1, isOpen } = this.state;


        let title = 'The Place Making Institute',
        description = `The Place Making Institute (PMI) serves to strengthen the capacity of planners and 
                        community leaders in historic/scenic cities and towns threatened by developer-influenced 
                        zoning boards.  In addition research will be sponsored through a fellows program that 
                        houses two to four planners in residence annually, taking advantage of the newly completed 
                        library which contains over 5000 publications. One of the key aspects of the PMI is managing 
                        the integrity of the community in its public space, architecture, civic institutions and 
                        design standards. The institute encourages the special character of places and focuses on enhancing 
                        the skills and techniques needed to sustain and apply them nationwide.`,
        description2 = `One of the key aspects of ‘Place Making’ is managing the integrity of the community
                        in its public space, architecture, civic institutions and design standards. The PMI
                        encourages increased respect for places and focuses on enhancing skills and
                        techniques which support communities across America.`,

        description3 = `The Institute is similar to the long-running Mayor’s Institute on City Design, which
                        brings mayors together with a design faculty to discuss specific issues relating to the
                        character of each city. This program was conceived in 1989 and has continued to the
                        present at the National Endowment for the Arts. Jeffrey Soule staffed the Mayor’s
                        Institute during the first several years with guidance from the founders, former
                        Mayor Joe Riley of Charleston, Jaqueline Robertson, then Dean of the School of
                        Architecture and Planning at the University of Virginia and Adele Chatfield-Taylor,
                        past Director of the NEA Design Arts Program.`,

        description4 = `Six to eight mayors come together with design professionals and each brings a
                        specific issue their city is facing. The faculty along with the mayors engages in lively
                        and insightful conversation illuminating ways to manage and solve city issues. In
                        addition, some faculty members offer short talks that illustrate their work and
                        general practices on topics such as economic development, cultural conservation or
                        design guidelines.`,

        description5 = `The PMI is conceived along the same contours, but aimed at planning directors and
                        local leaders rather than mayors.`,

        description6 = `Planning directors face challenges from politically appointed planning boards who
                        are often dominated by development interests. The concept behind the PMI is to
                        encourage planning directors from communities with great assets--historic character,
                        scenic beauty, geographic setting, and outstanding architectural ensembles to fortify
                        their capacity for providing strategic leadership. PMIs intention is to strengthen the
                        hand of the planners and community leaders by sharing experiences from other
                        locales, expanding strategies and crafting tools for implementation. In the future,
                        there may be regional variations hosted by faculty from the Institute.`
                                


        return(
            <React.Fragment>
                <PageHelmet pageTitle='About' />

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'About'}   />
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container" id="firstAnchor">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail" style= {{ fontFamily: 'raleway, sans-serif', fontSize: '14px'}}>
                                        <img className="w-100" src="/assets/images/about/library.jpg" alt="About Images"/>
                                        Photo By: Mick Hales 
                                        <div className="smallblurb"> 
                                            <p style={{fontFamily: 'raleway, sans-serif', paddingTop: '1.3em', fontWeight: '600'}}>The library at the Place Making Institute is influenced by the arts and crafts interiors of Craigside and Arundel Castles. This space was a collaboration between JP Couture and Ronald Lee Fleming.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title"><em>{title}</em></h2>
                                            <p className="description">{description}</p>
                                            <p className="description">{description2}</p>
                                            <p className="description">{description3}</p>
                                            <p className="description">{description4}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row row--35" style={{ paddingTop: 35 }}>
                              <div className="col-lg-12">
                                <div className="about-inner inner">
                                  <div className="section-title">
                                    <p className="description">{description5}</p>
                                    <p className="description">{description6}</p>
                                   
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
                {/* Start CounterUp Area */}
                <div className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white" data-black-overlay="1">
                    <div className="container">
                        <Link to="/map"><CounterOne /></Link>
                    </div>
                </div>
                <div className="photocred" style={{ fontFamily: 'raleway, sans-serif', color: '#000000', fontSize: '14px'}}>
                    <a>Photo By: Robert Kranz</a>
                </div>

                {/* End CounterUp Area */}

                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120 bg_color--1">
                    <div className="container" id="secondAnchor">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="single-column">
                                    <h4 className="tilte">Alliance of Cultural Towns</h4>
                                    <p>The PMI already maintains an ongoing inventory of small cities and towns in the
                                            United States that have an established sense of historic integrity, cultural
                                            consciousness, an architectural heritage and scenic distinction. The Institute
                                            incorporates the work that Professor Kent Schuette has done to identify such
                                            communities and provide information about the local population, college enrollment
                                            and endowments. The assumption is colleges and universities provide a thoughtful
                                            cadre to inform the assessment of community assets. i.e. The City of Claremont
                                            created the first design review ordinance in California which established the concept
                                            of community wide design review. This legislation differs from the national
                                            preservation law which enabled each state to employ its preservation office to identify
                                            historic districts or those eligible for designation on the National Register of Historic
                                            Places.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 mt_sm--30">
                                <div className="single-column">
                                    <h4 className="tilte">Fellowships</h4>
                                    <p>The Directors of the PMI will select up to four fellows competitively. Fellows will
                                        spend up to 30 days in residence at the Institute campus and use the library with its
                                        5,500 books and publications as a resource. The fellows will spark interventions to
                                        address current battles on issues affecting community character or to provide deep
                                        back ground in on going struggles regarding community identity. They may prepare
                                        books, newspaper articles, monographs, journal entries, electronic media including
                                        podcasts and other publications.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
 
                <div className="rn-columns-area ptb--120 bg_color--1">
                    <div className="container" id="thirdAnchor">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="single-column" style= {{ fontFamily: 'raleway, sans-serif'}}>
                                        {TabOne.map((value , index) => (
                                <div className="single-column" key={index} >
                                    {isOpen && (
                                        <Lightbox
                                            mainSrc={TabOne[tab1].bigImage}
                                            nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                                            prevSrc={TabOne[(tab1 + TabOne.length - 1) % TabOne.length]}
                                            imageLoadErrorMessage = {<div>Image is Loading...</div>}
                                            enableZoom= {false}
                                            imagePadding = {50}
                                            onCloseRequest={() => this.setState({ isOpen: false })}
                                            onMovePrevRequest={() =>
                                            this.setState({
                                                tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                                            })
                                            }
                                            onMoveNextRequest={() =>
                                                this.setState({
                                                    tab1: (tab1 + 1) % TabOne.length,
                                                })
                                            }
                                        />
                                    )}

                                    <div className="thumbnail" onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                        
                                        <h4 className="tilte">Site Plan</h4>
                                            <p>The PMI is located on the grounds of Bellevue House as illustrated in this traditional style site plan, like a Sanborn map, by architect J.P. Couture of Providence.</p>
                                            <br></br>
                                            <div className="grounds">
                                                <img className="w-100" src={`/assets/images/portfolio/dp-portfolio-${value.image}.jpg`} style={{ fontFamily: 'raleway, sans-serif', color: '#000000', fontSize: '14px'}} alt="About Images" />
                                                    Image By: J. P. Couture
                                            </div>
                                    </div>
                            </div>

                            ))}
                            </div>



                            </div>
                            <div className="col-lg-6 col-md-6 col-12 mt_sm--30">
                                <div className="single-column">
                                    <h4 className="tilte">Inside the Institute</h4>
                                    <p>Bellevue House will provide the Place Making Institute with an assortment of teaching and conference spaces. Shown below are the Greenhouse, Stable Block, Library, and Rotunda.<br></br><p>&nbsp;</p></p>
                                    <Carousel interval={200000} activeIndex={index} onSelect={this.handleSelect}>
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src="/assets/images/about/greenhouse.jpg"
                                                alt="First slide"
                                            />
                                            <Carousel.Caption>
                                                <h3 style={{fontFamily: 'raleway, sans-serif', fontSize: '1.8em', fontWeight: '800', color: '#ffffff'}}>Greenhouse</h3>
                                                <p style={{fontFamily: 'raleway, sans-serif', fontSize: '1.2em', color: '#ffffff', fontWeight: '800'}}>Photo By: Mick Hales </p>
                                            </Carousel.Caption>
                                        </Carousel.Item>

                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src="/assets/images/about/library.jpg"
                                                alt="Second Slide"
                                            />
                                            <Carousel.Caption>
                                                <h3 style={{fontFamily: 'raleway, sans-serif', fontSize: '1.8em', fontWeight: '800', color: '#ffffff'}}>Library</h3>
                                                <p style={{fontFamily: 'raleway, sans-serif', fontSize: '1.2em', color: '#ffffff', fontWeight: '800'}}>Photo By: Mick Hales </p>
                                            </Carousel.Caption>
                                        </Carousel.Item>


                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src="/assets/images/about/rotunda.jpg"
                                                alt="Third slide"
                                            />

                                            <Carousel.Caption>
                                                <h3 style={{fontFamily: 'raleway, sans-serif', fontSize: '1.8em', fontWeight: '800', color: '#ffffff'}}>Rotunda</h3>
                                                <p style={{fontFamily: 'raleway, sans-serif', fontSize: '1.2em',  fontWeight: '800'}}>Photo By: Mick Hales</p>
                                            </Carousel.Caption>
                                        </Carousel.Item>

                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src="/assets/images/about/stableblock.jpg"
                                                alt="Third slide"
                                            />

                                            <Carousel.Caption>
                                                <h3 style={{fontFamily: 'raleway, sans-serif', fontSize: '1.8em', fontWeight: '800', color: '#ffffff'}}>Stable Block</h3>
                                                <p style={{fontFamily: 'raleway, sans-serif', fontSize: '1.2em',  fontWeight: '800'}}>
                                                    Photo By: Mick Hales
                                    </p>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    </Carousel>



                            {/* End Slider Area   */}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Columns Area  */}


                {/* Start Team Area  */}
                <div className="rn-team-area bg_color--1 ptb--120">
                    <div className="container" id="fourthAnchor">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25">
                                    <h2 className="title"><em>Faculty</em></h2>
                                    <p>Members of the design faculty of the Institute represent experience in the areas of
                                        planning, urban design, historic preservation, architecture, municipal finance,
                                        sensitive urban development, scenic protection, land use, conservation law and the
                                        safeguarding of public space. Up to six faculty members from this pool participate in
                                        each program. Faculty members Professor Schuette and Mr. Winter have run similar
                                        programs which strengthen the PMI capabilities.</p>
                                       <p>In the future, there may be regional variations hosted by faculty from the Institute.</p>
                                    <img className="w-100" src="/assets/images/team/group.jpg" alt="Blog Images"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            {/* Start Single Team  */}

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <h6 className="title"><strong>Ronald Lee Fleming, FAICP, Host (Newport, RI)</strong></h6>
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-01.jpg" alt="Blog Images"/>
                                    </div>
                                    {/*
                                    <div className="content">
                                        <h4 className="title">Ronald Lee Flemming</h4>
                                        <p className="designation">Founder</p>
                                        <p className="designation">Founder of the Townscape Institute, Ron Fleming was an early advocate of the Main Street approach to revitalizing small towns. He has championed the integration of art in efforts to shape communities and their public spaces. </p>
                                    </div>
                                    */}
                                </div>
                                <div className="contentBelow">
                                        <h4 className="title">Ronald Lee Flemming</h4>
                                        <p className="designation2">Founder</p>
                                        <p className="designation">Founder of the Townscape Institute, Ron Fleming was an early advocate of the Main Street approach to revitalizing small towns. He has championed the integration of art in efforts to shape communities and their public spaces. </p>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                              <h6 style={{ }} className="title"><strong>Jeffrey Soule, FAICP (Washington, DC)</strong></h6>
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-02.jpg" alt="Blog Images"/>
                                    </div>
                                    {/*
                                    <div className="content">
                                        <h4 className="title">Jeffrey Soule</h4>
                                        <p className="designation">Mr. Soule established a variety of citizen-led planning efforts early in his career and pioneered grassroots planning at the Tug Hill Commission. He managed the Mayors Institute on City Design as well as ‘Your Town’ program at the National Endowment for the Arts.</p>
                                    </div>
                                     */}
                                </div>
                                <div className="contentBelow">

                                        
                                        <p className="designation">Mr. Soule established a variety of citizen-led planning efforts early in his career and pioneered grassroots planning at the Tug Hill Commission. He managed the Mayors Institute on City Design as well as ‘Your Town’ program at the National Endowment for the Arts.</p>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <h6 style={{ }} className="title"><strong>Kenneth Schuette (New Harmony, IN)</strong></h6>
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-03.jpg" alt="Blog Images"/>
                                    </div>
                                    {/*
                                    <div className="content">
                                        <h4 className="title">Kenneth Schuette</h4>
                                        <p className="designation">Trained as an Architect with an architectural degree, Kent Schuette has devoted his teaching and design career to identifying and incorporating the unique cultural uniqueness in both changing and conserving towns and neighborhoods.</p>
                                    </div>
                                     */}
                                </div>
                                <div className="contentBelow">
                                      
                                        <p className="designation">Trained as an Architect with an architectural degree, Kent Schuette has devoted his teaching and design career to identifying and incorporating the unique cultural uniqueness in both changing and conserving towns and neighborhoods.</p>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <h6 style={{ }} className="title"><strong>Nore Winter (Boulder, CO)</strong></h6>
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-04.jpg" alt="Blog Images"/>
                                    </div>
                                    {/*
                                    <div className="content">
                                        <h4 className="title">Nore Winter</h4>
                                        <p className="designation">Principal and Owner of Winter & Company, architect who has including designs in communities we have identified is an urban design and planning consultant with more than thirty years’ experience nationwide.</p>
                                    </div>
                                     */}
                                </div>
                                <div className="contentBelow">
                                     
                                        <p className="designation">Principal and Owner of Winter & Company, architect who has including designs in communities we have identified is an urban design and planning consultant with more than thirty years’ experience nationwide.</p>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <h6 style={{ }} className="title"><strong>Steven Semes (Notre Dame, IN)</strong></h6>
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-05.jpg" alt="Blog Images"/>
                                    </div>
                                    {/*
                                    <div className="content">
                                        <h4 className="title">Steven Semes</h4>
                                        <p className="designation">Prof. Semes practiced architecture for over 30 years before joining the Notre Dame faculty in 2005. Professor Semes has focused his writing and work on the recovery of the classical language of architecture and how this thinking leads to better conservation and interpretation of cultural heritage sites. </p>
                                    </div>
                                    */}
                                </div>
                                <div className="contentBelow">
                                     
                                        <p className="designation">Prof. Semes practiced architecture for over 30 years before joining the Notre Dame faculty in 2005. Professor Semes has focused his writing and work on the recovery of the classical language of architecture and how this thinking leads to better conservation and interpretation of cultural heritage sites. </p>
                                </div>
                            </div>
                            

                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <h6 style={{ }} className="title"><strong>Elizabeth Watson (Chestertown, MD)</strong></h6>
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-06.jpg" alt="Blog Images"/>
                                    </div>
                                    {/*
                                    <div className="content">
                                        <h4 className="title">Elizabeth Watson</h4>
                                        <p className="designation">Elizabeth Watson has served nonprofit and governmental clients as planner, project manager, and facilitator, specializing in planning for historic regions, places, and landscapes. Her insights into the planning process stem from work in heritage areas, scenic byways, historic communities, working waterfronts, and heritage tourism. </p>
                                    </div>
                                     */}
                                </div>
                                <div className="contentBelow">
                                  
                                        <p className="designation">Elizabeth Watson has served nonprofit and governmental clients as planner, project manager, and facilitator, specializing in planning for historic regions, places, and landscapes. Her insights into the planning process stem from work in heritage areas, scenic byways, historic communities, working waterfronts, and heritage tourism. </p>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <h6 style={{ }} className="title"><strong>Alan Feinberg (Frederick, MD)</strong></h6>
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-08.jpg" alt="Blog Images"/>
                                    </div>
                                    {/*
                                    <div className="content">
                                        <h4 className="title">Alan Feinberg</h4>
                                        <p className="designation">Mr. Feinberg is a licensed architect who has always had a keen interest in livability. As a community advocate and local leader, Alan has contributed to Frederick, Maryland for over 25 years. His Benefit LLC, The FeinDesignTeam enables a network of seasoned professionals to advance civic participation and design in creating better places.</p>
                                    </div>
                                */}
                                </div>
                                <div className="contentBelow">
                                     
                                        <p className="designation">Mr. Feinberg is a licensed architect who has always had a keen interest in livability. As a community advocate and local leader, Alan has contributed to Frederick, Maryland for over 25 years. His Benefit LLC, The FeinDesignTeam enables a network of seasoned professionals to advance civic participation and design in creating better places.</p>
                                </div>
                            </div>
                            {/* End Single Team  */}

                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default About
