import React from 'react';
import { Link } from 'react-router-dom';

const className = "active"

export default function Pagination(props) {

    return (
        <div className="rn-pagination text-center">
            <ul className="page-list">
                <li className={props.number1 ? 'active' : ''}><Link to="/news">1</Link></li>
                <li className={props.number2 ? 'active' : ''}><Link to="/news2">2</Link></li>
                <li className={props.number3 ? 'active' : ''}><Link to="/news3">3</Link></li>
            </ul>
        </div>
    )
}