import React from 'react';

export const TabOne = [
	{
		index: 0,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-01.jpg',
		title: 'Eufaula, Alabama',
		caption: '',
		state: 'Alabama'
	},
	{
		index: 1,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-02.jpg',
		title: 'Sitka, Alaska',
		caption: '',
		state: 'Alaska'
	},
	{
		index: 2,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-03.jpg',
		title: 'Bisbee, Arizona',
		caption: 'Credit: Phillip Capper',
		state: 'Arizona'
	},
	{
		index: 3,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-04.jpg',
		title: 'Downtown Arkadelphia, Arkansas',
		caption: 'Credit: Brandon Rush',
		state: 'Arkansas'
	},
	{
		index: 4,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-05.jpg',
		title: 'Monterey Wharf, California',
		caption: '',
		state: 'California'
	},
	{
		index: 5,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-06.jpg',
		title: 'Downtown Aspen, Colorado',
		caption: 'Credit: Matthew Trump',
		state: 'Colorado'
	},
	{
		index: 6,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-07.jpg',
		title: 'Greenwich, Connecticut',
		caption: '',
		state: 'Connecticut'
	},
	{
		index: 7,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-08.jpg',
		title: 'Milford, Delaware',
		caption: 'Credit: Tim Kiser',
		state: 'Delaware'
	},
	{
		index: 8,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-09.jpg',
		title: 'Saint Augustine, Florida',
		caption: 'Credit: Infrogmation',
		state: 'Florida'
	},
	{
		index: 9,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-10.jpg',
		title: 'Savannah, Georgia',
		caption: 'Credit: Elisa Rolle',
		state: 'Georgia'
	},
	{
		index: 10,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-11.jpg',
		title: 'Lahaina Front Street, Hawaii',
		caption: '',
		state: 'Hawaii'
	},
	{
		index: 11,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-12.jpg',
		title: 'Downtown Pocatello, Idaho',
		caption: '',
		state: 'Idaho'
	},
	{
		index: 12,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-13.jpg',
		title: 'Galena, Illinois',
		caption: 'Credit: Julien Scavini',
		state: 'Illinois'
	},
	{
		index: 13,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-14.jpg',
		title: 'Downtown New Harmony, Indiana',
		caption: 'Credit: Timothy Hamilton',
		state: 'Indiana'
	},
	{
		index: 14,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-15.jpg',
		title: 'Ames, Iowa',
		caption: 'Credit: Tim Kiser',
		state: 'Iowa'
	},
	{
		index: 15,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-16.jpg',
		title: 'Lawrence, Kansas',
		caption: 'Credit: Ian Ballinger',
		state: 'Kansas'
	},
	{
		index: 16,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-17.jpg',
		title: 'Bowling Green, Kentucky',
		caption: 'Credit: OP Master',
		state: 'Kentucky'
	},
	{
		index: 17,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-18.jpg',
		title: 'Ruston, Louisiana',
		caption: 'Creditt: Lindsay Attaway',
		state: 'Louisiana'
	},
	{
		index: 18,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-19.jpg',
		title: 'Rockport, Maine',
		caption: '',
		state: 'Maine'
	},
	{
		index: 19,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-20.jpg',
		title: 'Ellicott City, Maryland',
		caption: 'Credit: Acroterion',
		state: 'Maryland'
	},
	{
		index: 20,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-21.jpg',
		title: 'Deerfield, Massachusetts',
		caption: '',
		state: 'Massachusetts'
	},
	{
		index: 21,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-22.jpg',
		title: 'Houghton, Michigan',
		caption: '',
		state: 'Michigan'
	},
	{
		index: 22,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-23.jpg',
		title: 'Winona, Minnesota',
		caption: '',
		state: 'Minnesota'
	},
	{
		index: 23,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-24.jpg',
		title: 'Holly Springs, Mississippi',
		caption: 'Credit: Lucian Venutian',
		state: 'Mississippi'
	},
	{
		index: 24,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-25.jpg',
		title: 'Hannibal, Missouri',
		caption: 'Credit: Terry Ballar',
		state: 'Missouri'
	},
	{
		index: 25,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-26.jpg',
		title: 'Downtown Missoula, Montana',
		caption: '',
		state: 'Montana'
	},
	{
		index: 26,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-27.jpg',
		title: 'Downtown Wilbur, Nebraska',
		caption: '',
		state: 'Nebraska'
	},
	{
		index: 27,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-28.jpg',
		title: 'Virginia City, Nevada',
		caption: '',
		state: 'Nevada'
	},
	{
		index: 28,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-29.jpg',
		title: 'Hanover, New Hampshire',
		caption: 'Credit: Ken Gallagher',
		state: 'New Hampshire'
	},
	{
		index: 29,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-30.jpg',
		title: 'Cape May, New Jersey',
		caption: '',
		state: 'New Jersey'
	},
	{
		index: 30,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-31.jpg',
		title: 'Taos, New Mexico',
		caption: '',
		state: 'New Mexico'
	},
	{
		index: 31,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-32.jpg',
		title: 'Sag Harbor, New York',
		caption: '',
		state: 'New York'
	},
	{
		index: 32,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-33.jpg',
		title: '',
		caption: '',
		state: ''
	},
	{
		index: 33,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-34.jpg',
		title: 'Chapel Hill, North Carolina',
		caption: '',
		state: 'North Carolina'
	},
	{
		index: 34,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-35.jpg',
		title: 'Grand Forks, North Dakota',
		caption: '',
		state: 'North Dakota'
	},
	{
		index: 35,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-36.jpg',
		title: 'Downtown Blufton, Ohio',
		caption: 'Credit: Major Optics',
		state: 'Ohio'
	},
	{
		index: 36,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-37.jpg',
		title: 'Stillwater, Oklahoma',
		caption: 'Credit: Fletcherspears',
		state: 'Oklahoma'
	},
	{
		index: 37,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-38.jpg',
		title: 'Jacksonville, Oregon',
		caption: 'Credit: Joen Mabel',
		state: 'Oregon'
	},
	{
		index: 38,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-39.jpg',
		title: 'Stillwater, Pennsylvania',
		caption: '',
		state: 'Pennsylvania'
	},
	{
		index: 39,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-40.jpg',
		title: 'Newport, Rhode Island',
		caption: 'Credit: Doug Kerr',
		state: 'Rhode Island'
	},
	{
		index: 40,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-41.jpg',
		title: 'Charleston, South Caroline',
		caption: 'Credit: Elisa Rolle',
		state: 'South Carolina'
	},
	{
		index: 41,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-42.jpg',
		title: 'Vermillion, South Dakota',
		caption: '',
		state: 'South Dakota'
	},
	{
		index: 42,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-43.jpg',
		title: '',
		caption: '',
		state: ''
	},
	{
		index: 43,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-44.jpg',
		title: 'Clarksville, Tennessee',
		caption: 'Credit: Bob Jagendorf',
		state: 'Tennessee'
	},
	{
		index: 44,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-45.jpg',
		title: 'Fredericksburg, Texas',
		caption: 'Credit: Prieselbeere',
		state: 'Texas'
	},
	{
		index: 45,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-46.jpg',
		title: 'Downtown Provo, Utah',
		caption: 'Credit: Javin Weaver',
		state: 'Utah'
	},
	{
		index: 46,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-47.jpg',
		title: 'Burlington, Vermont',
		caption: '',
		state: 'Vermont'
	},
	{
		index: 47,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-48.jpg',
		title: 'Charlottesville, Virginia',
		caption: '',
		state: 'Virginia'
	},
	{
		index: 48,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-49.jpg',
		title: 'Port Townsend, WA',
		caption: '',
		state: 'Washington'
	},
	{
		index: 49,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-50.jpg',
		title: 'Berkeley Springs, West Virginia',
		caption: '',
		state: 'West Virginia'
	},
	{
		index: 50,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-51.jpg',
		title: 'Madison, Wisconsin',
		caption: '',
		state: 'Wisconsin'
	},
	{
		index: 51,
		image: '/assets/images/portfolio/bigMap/map-big--portfolio-52.jpg',
		title: 'Downtown Laramie, Wyoming',
		caption: 'Credit: Matthew Trump',
		state: 'Wyoming'
	},

]
