import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive.js";
import Pagination from "../elements/common/Pagination";
import FooterTwo from "../component/footer/FooterTwo";
import ReactTooltip from 'react-tooltip';

class News extends Component{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    constructor () {
        super()
        this.state = {
          isOpen: false,
          currentPage: 1
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='News' />
                {/* End Pagehelmet  */}

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'News'}   />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">

                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">

                                    {/* Start Single Area */}

                                        <div className="row sercice-details-content pb--80 align-items-center">

                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <a href="https://www.scenic.org/blog/place-making-spotlight-newport-rhode-island/" target="_blank"  rel="noopener noreferrer">
                                                    <div className="photoHover">
                                                    <img className="w-100" src="/assets/images/news/news-06.jpg"  rel="noopener noreferrer" alt="Adventures of A Narrative Gardener Book Cover" />
                                                    (Image courtesy of Discover Newport)
                                                    </div>
                                                    </a>
                                                </div>
                                            </div>

                                        <div className="col-lg-6 col-12">
                                                <div className="details mt_md--3 mt_sm--30">
                                                    <h4 className="title" data-tip data-for="amazon">
                                                    <a href="https://www.scenic.org/blog/place-making-spotlight-newport-rhode-island/" target="_blank"  rel="noopener noreferrer">
                                                    Place-Making Spotlight: Newport, Rhode Island
                                                    </a>
                                                    <ReactTooltip id="amazon" place="bottom" effect="solid">
                                                        <img height='160' width='240' src='assets/images/jan14.png' alt="Newport Harbor" />
                                                    </ReactTooltip>
                                                    </h4>
                                                    <h3 className="title" style ={{fontFamily: 'raleway, sans-serif'}}>January 14th, 2021 </h3>
                                                    <p>"Well known for its stunning Gilded Age mansions and historic waterfront, the city of Newport, Rhode Island, boasts incredible examples of architecture and
                                                     a well-documented public realm. Even with such a gloried past, however, the city today presents a mixed design picture. Its architectural landscape was significantly
                                                      altered in the twentieth century as planners eschewed tradition for the latest development trends. 
                                                     As a result, Newport today lacks a clear design vision without defined standards—a situation that design leaders and city officials are looking to remedy."
                                                    </p>
                                                    <p>-Rebecca Aloisi</p>                      
                                                </div>
                                            </div>
                                        </div>
                                        <div className="divider" style={{width: '90%', marginLeft: '5%', marginBottom: '50px', borderBottomStyle: 'solid', borderColor: '#661B0B'}}>
                                        </div>
                                    {/* End Single Area */}


                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-7 col-12">
                                                <div className="details mt_md--30 mt_sm--30" >
                                                    <h4 className="title" data-tip data-for="bloomberg" >
                                                    <a href="https://www.scenic.org/blog/place-making-spotlight-cumberland-ri/" target="_blank" rel="noopener noreferrer" >
                                                    Place Making Spotlight: Cumberland, RI
                                                    </a>
                                                    <ReactTooltip id="bloomberg" place="bottom" effect="solid">
                                                        <img height='160' width='240' src='assets/images/dec10.png' alt="Cumberal Town Hall" />
                                                    </ReactTooltip>
                                                    </h4>
                                                    <h3 className="title" style ={{fontFamily: 'raleway, sans-serif'}}>December 10th, 2020</h3>
                                                    <p>"Tucked in the northeast corner of Rhode Island between Providence and Boston, the town of Cumberland embraces its natural beauty and rich history. 
                                                    With its commitment to smart, responsible growth and design enhancements showcasing historic sites and municipal parklands, 
                                                    Cumberland prides itself in its place-making practices.  </p>
                                                    <p>Cumberland contains three historically significant mill villages on the Blackstone River, an area known as the birthplace of the American Industrial Revolution. 
                                                    Built in the last half of the nineteenth century by the Lonsdale Company, these 
                                                    mills are being adaptively reused for housing and business use. The Town has enacted a local historic district ordinance to protect these sites."</p>
                                                    <p>-Rebecca Aloisi</p>   
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-12">
                                                <div className="thumb position-relative">
                                                    <a href="https://www.scenic.org/blog/place-making-spotlight-cumberland-ri/" target="_blank"  rel="noopener noreferrer">
                                                    <div className="photoHover">
                                                    <img className="w-100" src="/assets/images/news/news-05.jpg"  rel="noopener noreferrer" alt="Adventures of A Narrative Gardener Book Cover" />
                                                    (Image courtesy of Scenic America)
                                                    </div>
                                                    </a>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="row mt--20">
                            <div className="col-lg-12">
                                {/* Start Pagination Area */}
                                <Pagination number1={true} number2={false} number3={false}/>
                                {/* End Pagination Area */}
                            </div>
                        </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </React.Fragment>
        )
    }
}


export default News;
