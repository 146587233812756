// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import CorporateBusiness from './home/CorporateBusiness.js';

// Element Layout
import News from "./elements/News.js";
import News2 from "./elements/News2";
import News3 from "./elements/News3";
import Map from "./elements/map.js";
import About from "./elements/About.js";
import Contact from "./elements/Contact";
import error404 from "./elements/error404";


// Blocks Layout
import Gallery from "./blocks/Gallery";

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';


class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={CorporateBusiness}/>

                    {/* Element Layot */}

                    <Route exact path={`${process.env.PUBLIC_URL}/news`} component={News}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/news2`} component={News2}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/news3`} component={News3}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/bellevuehouse`} component={Gallery}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/map`} component={Map}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>


                    {/* Blocks Elements  */}
                    <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery}/>

                {/* I dont think we need this route below */}
                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                    <Route component={error404}/>

                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();
