import React, { useState } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive.js";
import FooterTwo from "../component/footer/FooterTwo";
import ReactTooltip from 'react-tooltip';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { AiOutlineClose } from "react-icons/ai";
import { TabOne } from "./tab/towns.js";

import {
  ComposableMap,
  Geographies,
  Geography,
  Annotation,
  Marker,
  
 } from "react-simple-maps";

import { geoCentroid } from "d3-geo";
// import MapChart from "./MapChart";
import allStates from "./tab/allstates.json";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json"

const Map = (props) => {

  const [content, setContent] = useState("");
  const [index, setIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [tab, setTab] = useState(0)

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21]
};

function onStateHover(key) {

    if (key === "geo-25") {
      setContent(
      <div className="locationContainer">
        <ul>
        <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>California</strong></li>
          <div className="secondList">
            <li>Ojai (2020 – 7,442) | Monterey (2018-28,178)</li>
            <li>San Marcos 2018 – 96,847) |  Camarillo (2019 – 69,888)</li>
            <li>Arcata (2020 – 18,358) | Rohnert Park (2020 – 44,015)</li>
            <li>Healdsburg (2020 – 12,086) | Claremont (2020 – 36,374)</li>
            <li>Eureka (2011-27,191)  | Sea Ranch (2011- 1,305)</li>
            <li>Nevada City (2019-31,48) | Grass valley (2019-12,817)</li>
            <li>Yreka (2018-7,518) | Santa Barbara ( 2019-91,364)</li>
            <li>Paso Robles (2019-32,153) | San Luis Obispo (2019-47,459)</li>
            <li>Healdsburg (2018-11,845) | Stockton (2019-312.617) </li>
            <li>Idyllwild (3,454)</li>
          </div>
        </ul>
          <div className='hoverMenu'>
            <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(4);}}}>Town Photo Gallery</button>
            <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
          </div>
      </div>
    )
  }  else if (key === "geo-0") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Alabama</strong></li>
        <div className="secondList">
          <li>Montevallo (2018–6,674)</li>
          <li>Florence (2018–40,428)</li>
          <li>Livingston (2011–3,406)</li>
          <li>Tuscaloosa (2018-101,113)</li>
          <li>Auburn (2018–65,738)</li>
          <li>Eufaula (2011- 13,137)</li>
          <li>Fairhope (2011-15,326)</li>
        </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(0);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-1") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Alaska</strong></li>
        <div className="secondList">
          <li>Juno (2011–32,164)</li>
          <li>Utqiagvic (2019–4,467)</li>
          <li>Sitka (2018-8,881)</li>
          <li>Talkeetna (874)</li>
        </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(1);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-2") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Arizona</strong></li>
        <div className="secondList">
          <li>Jerome (2020–457)</li>
          <li>Bisbee (2020–5,155)</li>
          <li>Coolidge (2018–12,993)</li>
          <li>Holbrook (2020–5,159)</li>
          <li>Thatcher (2020–5,148)</li>
          <li>Sedona (2020–10,301) </li>
          <li>Flagstaff (2020–5,752)</li>
          <li>Scottsdale (2019-258,069) </li>
          <li>Tombstone (2018-1,300) </li>
          <li>Wickenburg (2019-8,092)</li>
        </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(2);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-24") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Arkansas</strong></li>
        <div className="secondList">
          <li>Searcy (2020 – 23,556)</li>
          <li>Fayetteville (2020 – 90,757)</li>
          <li>Arkadelphia (2019 – 10,009)</li>
          <li>Magnolia (2020 – 11,221)</li>
          <li>Hot springs (2019-38,797)</li>
          <li>Benton (2019-36,820)</li>
          <li>Little Rock (197,312)</li>
        </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(3);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-4") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Florida</strong></li>
        <div className="secondList">
          <li>Miami Shores (2018-10,562)</li>
          <li>St. Augustine (2018-14,567) </li>
          <li>Winter Park (2019 – 31,059)</li>
          <li>Gainesville (2018 – 133,857)</li>
          <li>Tallahassee (2018 – 193,551)</li>
          <li>De Funiak Springs(2018-6,719)</li>
          <li>Sarasota (58,285)</li>
          <li>Delray Beach |  Key West</li>
        </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(8);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-5") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Georgia</strong></li>
        <div className="secondList">
            <li>Athens (2018 – 123,330) </li>
            <li>Savannah (2018 – 145,862) </li>
            <li>Madison (2020 – 4,271)</li>
            <li>Monroe (13,673)</li>
            <li>Morrow (2018 – 7,494) </li>
            <li>Columbus (195,769)</li>
          </div>
        </ul>
          <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(9);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-6") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Indiana</strong></li>
      <div className="secondList">
      <li>New Harmony (2020 – 751) </li>
        <li>Madison (2020 – 11,663) </li>
        <li>Columbus (2020 – 47,665) </li>
        <li>Bloomington (2020 – 85,981)  </li>
        <li>West Lafayette (2020 – 48,398)  </li>
        <li>Greencastle (2020 - 10,700) </li>
        <li>Crawfordsville (2020 – 16,258) </li>
        <li>Hanover (2020 - 3,476) </li>
        <li>Muncie (2020 – 68,083) </li>
        </div>
        </ul>
          <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(13);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-7") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Kansas</strong></li>
      <div className="secondList">
      <li>Manhattan (2020 – 54,749) </li>
      <li>Lawrence (2019 – 102,980) </li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(15);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-8") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Maine</strong></li>
      <div className="secondList">
      <li>Rockport (2020 – 3,356)</li>
      <li>Wiscasset (2020 – 1,042)</li>
      <li>Auburn (2020 – 23,256) </li>
      <li>Brunswick (2020 – 15,244)</li>
      <li>Orono (2020 – 9,474) </li>
      <li>Lewiston (2020 – 107,899) </li>
      <li>Skowhegan (8,552)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(18);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-9") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Massachusetts</strong></li>
      <div className="secondList">
      <li>Paxton (2019 – 4,870)</li>
      <li>Bridgewater (2020 – 5,139) </li>
      <li>Milton (2020 – 27,568)</li>
      <li>Wenham (2010 – 4,875)</li>
      <li>South Hadley (2019 – 17,763) </li>
      <li>Norton (2010 – 19,031)</li>
      <li>Williamstown (2020 – 4,446) </li>
      <li>Dudley (2010 – 11,390) </li>
      <li>Amherst (2020 – 20,253)</li>
      <li>Southborough (9,767)</li>
      <li>Stockbridge (1,947)</li>
      <li>Deerfield (5,125)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(20);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-10") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Minnesota</strong></li>
      <div className="secondList">
      <li>Bemidji (2020 – 16,348) </li>
      <li>St. Joseph (2020 – 7,839) </li>
      <li>St. Bonifacius (2020 – 2,283) </li>
      <li>Morris (2020 – 5,319)</li>
      <li>Winona (2020 – 26,383) </li>
      <li>Moorhead ( 2020 – 44,753)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(22);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-11") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>New Jersey</strong></li>
      <div className="secondList">
      <li>W. Long Branch (2020 – 7,887) </li>
      <li>New Brunswick (2020 – 55,960) </li>
      <li>Montclair (2020 – 11,744)</li>
      <li>Princeton (28,307) </li>
      <li>Lawrenceville (3,887)</li>
      <li>Margate City (6,354)</li>
      <li>Cape may (3,442)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(29);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-12") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>North Carolina</strong></li>
      <div className="secondList">
      <li>Salisbury (2020 – 34,058)</li>
      <li>Boone (2020 – 20,254)</li>
      <li>Elon (2020 – 12,105)</li>
      <li>Boiling Springs (2020 – 4,481) </li>
      <li>Pembroke (2020 – 2,957) </li>
      <li>Cullowhee (2020 – 6,139)</li>
      <li>Flat Rock (2020 – 3,466) </li>
      <li>Chapel Hill (2020 – 59,606)</li>
      <li>Davidson (2020 – 13,393)</li>
      <li>Greenville (2020 – 95,183)</li>
      <li>Concord (96,341)</li>
      <li>Highlands (969)</li>
      <li>Brevard (7,900)</li>
      <li>Blowing Rock (1,307)</li>
      <li>Hendersonville (14,107)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(33);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-13") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>North Dakota</strong></li>
      <div className="secondList">
      <li>Grand Forks (2020 – 57,060) </li>
      <li>Bismark (2020 – 74,162) </li>
      <li>Dickinson (2020 – 21,835) </li>
      <li>Mayville (2020 – 1,761) </li>
      <li>Valley City (2020 – 6,164)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(34);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-14") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Oklahoma</strong></li>
      <div className="secondList">
      <li>Langston (2020 – 1,852)</li>
      <li>Goodwell (2020 – 1,272) </li>
      <li>Stillwater (2020 – 50,941) </li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(36);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-15") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Pennslyvania</strong></li>
      <div className="secondList">
      <li>Langston (2020 – 1,852)</li>
      <li>Goodwell (2020 – 1,272) </li>
      <li>Stillwater (2020 – 50,941) </li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(38);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-16") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>South Dakota</strong></li>
      <div className="secondList">
      <li>Madison (2020 – 7,611)</li>
      <li>Vermillion (2020 – 10,961) </li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(41);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-17") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Texas</strong></li>
      <div className="secondList">
      <li>Fredericksburg (2020 – 11,690)</li>
      <li>Georgetown (49,962)</li>
      <li>Port Arthur (2020 – 54,766) </li>
      <li>Brenham (2020 – 17,442) </li>
      <li>San Marcus (2020 – 66,583) </li>
      <li>College Station (2020 – 121,526) </li>
      <li>Denton (2020 – 142,173) </li>
      <li>Edinburgh (2020 – 104,189)</li>
      <li>Canyon (2020 – 16,495)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(44);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-18") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Wyoming</strong></li>
      <div className="secondList">
      <li>Laramie (2020 – 33,295)</li>
      <li>Cody (9,828)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(51);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-19") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Connecticut</strong></li>
      <div className="secondList">
      <li>Storrs (2020-16,516) | Willimantic (2020 -17,184)</li>
      <li>Old Lyme (2018 – 7,366) | Winsted (2020 – 7,367) </li>
      <li>Norwich (2018-39,768) | New London (2018-26,858)</li>
      <li>Mystic (2011-4,205) | Colchester (2011-16,068)</li>
      <li>Danbury (2019-84,594) | Torrington (2019-34,044)</li>
      <li>Pomfret (2018-4,203) | Manchester (2019-57,584)</li>
      <li>Middletown (2019-46,258) | Essex (2018-6,668)</li>
      <li>Wethersfield (2019-26,008) | Greenwich (2019-62,820)</li>
      <li>Waterbury (2018-107,568) | Litchfield (2018-8,094)</li>
      <li>New Canaan (2018-20,233)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(6);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-20") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Missouri</strong></li>
      <div className="secondList">
      <li>Hannibal (2020 – 17,367) </li>
      <li>Kirksville (2020 – 17,838)  </li>
      <li>Fulton (2020 – 12,317)  </li>
      <li>Columbia (2020 – 125,976)  </li>
      <li>Rolla (20,431) </li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(24);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-21") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>West Virginia</strong></li>
      <div className="secondList">
      <li>Shepherdstown (2020 – 1,862)</li>
      <li>Berkeley (2020 – 121,355)</li>
      <li>Athens (2020 – 883) </li>
      <li>Glenville (2020 – 1,412) </li>
      <li>West Liberty (2020 – 1,445)</li>
      <li>Beckley (2020 – 15,547) </li>
      <li>Bethany (2020 – 805)</li>
      <li>Huntington (2020 – 44,416)</li>
      <li>Morgantown (2020 – 30,983)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(49);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-22") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Illinois</strong></li>
      <div className="secondList">
      <li>Galena (2020 – 3,103)</li>
        <li>Elsah (2018 – 606) </li>
        <li>Lebanon (2018 – 4,274)</li>
        <li>Greenville (2018 – 6,453) </li>
        <li>Eureka (2018 – 5,282) </li>
        <li>Carlinville (2020 – 5,481) </li>
        <li>DeKalb (2020 – 42,603) North </li>
        <li>Champaign (2020 – 89,149) </li>
        <li>Normal (2020 – 54,872) </li>
        <li>Edwardsville (2020 – 24,867)</li>
        <li>Carbondale (2020 – 24,730)</li>
        <li>Pullman (6,501)</li>
        <li>Highland Park (29,515)</li>
        </div>
        </ul>
          <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(12);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-23") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>New Mexico</strong></li>
      <div className="secondList">
      <li>Las Vegas (2020 – 12,821)</li>
      <li>Portales (2020 – 11,470)</li>
      <li>Socorro (2019 – 9,051) </li>
      <li>Las Cruces (2020 – 104,148)</li>
      <li>Taos (2020 – 5,895) </li>
      <li>Abiquiu (231)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(30);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-3") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Colorado</strong></li>
      <div className="secondList">
      <li>Durango (2019 – 18,985)</li>
      <li>Aspen (2020 – 7,287) </li>
      <li>Golden (2020 – 22,270) </li>
      <li>Crested Butte (2018-1,723)</li>
      <li>Telluride (2018-2,479)</li>
      <li>Boulder (2018-105,649) </li>
      <li>Steamboat springs (2019-13,214)</li>
      <li>Georgetown (2019-1,112)</li>
      <li>Central city (2019-770)</li>
      <li>Cripple Creek (2019- 1,258))</li>
    </div>
    </ul>        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(5);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-26") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Delaware</strong></li>
      <div className="secondList">
      <li>New Castle (2018 – 5,529)</li>
      <li>Newark (2018 – 33,673)</li>
      <li>Milford (11,732)</li>
      <li>Georgetown (7,583)</li>
      <li>Lewes (2,747)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(7);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-28") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Hawaii</strong></li>
      <div className="secondList">
      <li>Hanelei (2020 – 267)</li>
        <li>Hana (956)</li>
        <li>Kailua-Kona (11,975)</li>
        <li>Hilo (46,284)</li>
        <li>Lahaina (13,101)</li>
        </div>
        </ul>
          <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(10);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-29") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Iowa</strong></li>
      <div className="secondList">
      <li>Ames (2020 – 67,962)</li>
      <li>Lamoni ( 2020 – 2,182) </li>
      <li>Mt. Vernon (2020 – 4,510)</li>
      <li>Iowa City (2020 – 78,440) </li>
      <li>Cedar Falls (2020 – 40,390)</li>
      <li>Decorah (2018 – 7,594) </li>
      <li>Pella</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(14);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-30") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Kentucky</strong></li>
      <div className="secondList">
      <li>Franklin (2020 – 9,010)</li>
        <li>Berea (2020 – 16,617) </li>
        <li>Morehead (2020 – 7,730)</li>
        <li>Murray (2020 – 19,604) </li>
        <li>Highland Hgts. (2020 – 7,043) </li>
        <li>Danville (2020 – 16,854) </li>
        <li>Paducah (2020 – 24,672) </li>
        <li>Bowling Green (2020 – 71,623) </li>
        <li>Richmond (2020 – 37,652)</li>
        <li>Booneville (158)</li>
        <li>Paris (9,671)</li>
        </div>
        </ul>
          <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(16);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-31") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Maryland</strong></li>
      <div className="secondList">
      <li>Chestertown (2020 -4,966)</li>
      <li>St. Michaels (2020 – 1,018)</li>
      <li>Ellicott City (2020 – 65,526)</li>
      <li>Frostburg (2020 – 8,470)</li>
      <li>Annapolis (2020 – 39,014) </li>
      <li>Emmetsburg (2019 – 3,198) </li>
      <li>Princess Anne (2020 – 3,547) </li>
      <li>College Park (2020 – 32,200) </li>
      <li>Easton (16,671)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(19);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-32") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Michigan</strong></li>
      <div className="secondList">
      <li>Marshall (2020 – 6,977)</li>
      <li>Allendale (2020 – 20,776) </li>
      <li>Berrien Springs (2020 – 1,723) </li>
      <li>Houghton (2020 – 7,947) </li>
      <li>Mount Pleasant (2020 – 24,764) </li>
      <li>Spring Arbor (2020 – 2,634)</li>
      <li>Bloomfield Hills (2020 – 4,077)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(21);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-33") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Mississippi</strong></li>
      <div className="secondList">
      <li>Natchez (2020 – 14,809)</li>
      <li>Columbia (2020 – 5,933)</li>
      <li>Holly Springs (2020 – 7,722) </li>
      <li>Oxford (2020 – 25,884) </li>
      <li>Starkville (2020 – 25,543)</li>
      <li>Hattiesburg (2020 – 45,309)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(23);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-34") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Montana</strong></li>
      <div className="secondList">
      <li>Dillon (2020 – 4,249)</li>
      <li>Havre (2020 – 9,751)</li>
      <li>Missoula (2020 – 77,058) </li>
      <li>Bozeman (2019 – 49,831) </li>
      <li>Virginia City (216)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(25);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-35") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>New Hampshire</strong></li>
      <div className="secondList">
      <li>Hancock (2020 – 242)</li>
      <li>Lancaster (2010 – 3,507)</li>
      <li>Plymouth (2020 – 3,163) </li>
      <li>Durham (2020 – 11,101) </li>
      <li>Hanover (2020 – 8,591) </li>
      <li>Corinth (1,649)</li>
      <li>Dublin (1,597)</li>
      <li>Peterborough (6.284)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(28);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-36") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>New York</strong></li>
      <div className="secondList">
      <li>Hamilton (2020 – 4,059)  |  Hyde Park (2020 – 1,982)</li>
      <li>Orangeburg (2020 – 4,200)  | Houghton (2020 – 1,761)</li>
      <li>Keuka Park (2020 – 975)  | Brookville (2020 – 3,638)</li>
      <li>Old Westbury (2020 – 4,768)  | St. Bonaventure (2020 – 1,947)</li>
      <li>Sparkill (2020 – 1,785)  | New Paltz (2020 – 7,335)</li>
      <li>Stony Brook (2020 – 12,641) |  Morrisville (2020 – 1,718)</li>
      <li>Delhi (2020 – 3,064)  | Aurora (2020 – 632)</li>
      <li>Clinton (2020 – 1,706)  | Sharon Springs (2020 – 522)</li>
      <li>Corning (10,538)  | Sag Harbor (2,283)</li>
      <li>Oyster Bay (6,707)  | Cooperstown (1,754)</li>
      <li>Sacketts Harbor (1,450)  | Woodstock (5,923)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(31);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-37") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Ohio</strong></li>
      <div className="secondList">
      <li>Blufton (2020 – 4,008) </li>
      <li>Cedarville (2020 – 3,992) </li>
      <li>Hiram (2020 – 1,071) </li>
      <li>Gambier (2020 – 2,512) </li>
      <li>New Concord (2020 – 2,041) </li>
      <li>Kent (2020 – 29,448)</li>
      <li>Oxford (2020 – 23,859) </li>
      <li>Athens (2020 – 24,588) </li>
      <li>Bowling Green (2020 – 32,350)</li>
      <li>Oberlin (8.199)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(35);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-38") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Oregon</strong></li>
      <div className="secondList">
      <li>Jacksonville (2020 -2 ,912)</li>
      <li>Lagrange (2020 – 30,173) </li>
      <li>Ashland (2020 – 21,621) </li>
      <li>Monmouth (2020 – 10,895) </li>
      <li>Corvallis (2020 – 61,027)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(37);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-39") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Tennessee</strong></li>
      <div className="secondList">
      <li>Martin (2020 – 10,188) </li>
      <li>Clarksville (2020 – 130,008) </li>
      <li>Johnson City (2020 – 66,936)</li>
      <li>Murfreesboro (2020 – 151,066) </li>
      <li>Franklin (80,914)</li>
      <li>Chattanooga (182,760)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(43);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-40") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Utah</strong></li>
      <div className="secondList">
      <li>Cedar City (2020 – 34,439) </li>
      <li>Logan (2018 - 51,542) </li>
      <li>Provo (2020 – 118,592)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(45);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  }  else if (key === "geo-41") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Virginia</strong></li>
      <div className="secondList">
      <li>Emory (2020 – 1,298) </li>
      <li>Fairfax (2020 – 25,228)</li>
      <li>Hampden –Syd (2020 – 1,716)</li>
      <li>Wise (2020 – 2,873) </li>
      <li>Charlottesville (2020 - 49,449)</li>
      <li>Abingdon (7,867)</li>
      <li>Waterford (2,231)</li>
      <li>Roanoke (99,143)</li>
      <li>Chincoteague (2,879)</li>
      <li>Charles City (143)</li>
      <li>Staunton (24,922)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(47);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-42") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
        <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Washington</strong></li>
          <div className="secondList">
            <li>Port Townsend (2020 – 9,946)</li>
            <li>Olympia (2020 – 54,427) </li>
            <li>Pullman (2020 – 34,833)</li>
          </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(48);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-43") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Wisconsin</strong></li>
      <div className="secondList">
      <li>Whitewater (2020 – 15,127) </li>
      <li>Kenosha (2020 – 101,124) </li>
      <li>Mequon (2020 – 24,281) </li>
      <li>Appleton (74,098) </li>
      <li>Madison (259,617)</li>
      <li>Lake Geneva (4,099)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(50);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-47") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Nebraska</strong></li>
      <div className="secondList">
      <li>Wayne (2020 – 5,360)</li>
      <li>Chadron (2020 – 5,430)</li>
      <li>Peru (2020 – 716) </li>
      <li>Wilbur</li>
      <li>Sidney</li>
      <li>North Platt</li>
      <li>Fremont</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(26);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-48") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>South Carolina</strong></li>
      <div className="secondList">
      <li>Due West (2020 – 1,160)</li>
      <li>Central (2020 – 5,367)</li>
      <li>Clemson (2020 – 18,710) </li>
      <li>Charleston (130,113)</li>
      <li>Beaufort (13,357)</li>
      <li>Georgetown (8,779)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(40);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-52") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Nevada</strong></li>
      <div className="secondList">
      <li>Elko (2020 – 17,956)</li>
      <li>Virginia City (855) </li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(27);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-53") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Vermont</strong></li>
      <div className="secondList">
      <li>Bennington (2020 – 8,836) </li>
      <li>Grafton (2020 – 697)</li>
      <li>Castleton (2020 – 1,327) </li>
      <li>Plainfield (2020 – 400) </li>
      <li>Lyndonville (2020 – 1,165)</li>
      <li>Marlboro (2020 – 1,163)</li>
      <li>Montpelier (2020 – 7,356)</li>
      <li>Craftsbury Common (2020 – 1,136) </li>
      <li>Peacham (724)</li>
      <li>Castleton (4,529)</li>
      <li>Bellows Falls (2,965)</li>
      <li>Burlington (42,899)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(46);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-54") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Louisiana</strong></li>
      <div className="secondList">
      <li>Natchitoches (2020 – 17,707) </li>
      <li>Grambling (2020 – 5,154)</li>
      <li>Hammond (2020 -20,820) </li>
      <li>Ruston (2020 – 22,151) </li>
      <li>Lafayette (2020 – 124,561) </li>
      <li>Mandeville (12,475)</li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(17);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-55") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Rhode Island</strong></li>
      <div className="secondList">
      <li>Newport (2020 – 24,238)</li>
      <li>Cumberland (2020 – 7,915)</li>
      <li>Pawtucket (71,847) </li>
      <li>Kingston (6,974) </li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(39);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else if (key === "geo-51") {
    setContent(
      <div className="locationContainer">
      <ul style={{listStyleType: 'none'}}>
      <li style={{fontSize: '2em', fontFamily: 'raleway'}}><strong>Idaho</strong></li>
      <div className="secondList">
      <li>Coeur d’Alene (2018 – 51,303)</li>
      <li>Moscow (2020 – 26,668)</li>
      <li>Lewiston 2020 – 33,155) </li>
      <li>Pocatello (2020 – 57,166) </li>
      </div>
      </ul>
        <div className='hoverMenu'>
          <button className="btn btn-info" onClick={() => {setOpen(true); {setIndex(11);}}}>Town Photo Gallery</button>
          <button className="btn btn-info" onClick={() => {setContent("") }}><AiOutlineClose/></button>
        </div>
      </div>
    )
  } else {setContent("")}


}




//check for nuber 27 later cant tell from map
        return(
            <React.Fragment>
            {isOpen && (
                        <Lightbox

                            mainSrc={TabOne[index].image}
                            //nextSrc={TabOne[(index + 1) % TabOne.length]}
                            //prevSrc={TabOne[(index + TabOne.length - 1) % TabOne.length]}
                            imageLoadErrorMessage = {<div></div>}
                            enableZoom= {false}
                            onCloseRequest={() => setOpen(false)}
                            onMovePrevRequest={() => setIndex((index + TabOne.length - 1) % TabOne.length)}
                            onMoveNextRequest={() => setIndex(((index + 1) % TabOne.length))}
                            imageTitle={TabOne[index].title}
                            imageCaption={TabOne[index].caption}
                            imagePadding={45}
                   
                        />
                    )}

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Locations' />
                {/* End Pagehelmet  */}

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Locations'}   />
                {/* End Breadcrump Area */}


           
                        <div className="row" style={{marginTop: '40px'}}>
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25">
                                    <h2 className="title"><em>450+ Cultural Towns</em></h2>
                                    <p>The design faculty of the Institute will represent experience in the areas of planning, urban design,
                                     historic preservation, architecture, municipal finance, sensitive urban development, scenic protection, 
                                     land use, conservation law and the protection of public space.</p>

                                </div>
                            </div>
                        </div>
                  
                {/* Start MAP Wrapper */}

                <div>
                <ComposableMap data-tip="" projection="geoAlbersUsa" style={{ backgroundColor: '#e4d7b7'}}>
                    <Geographies geography={geoUrl}>
                      {({ geographies }) => (
                        <>
                          {geographies.map(geo => (
                            <Geography
                              key={geo.rsmKey}
                              geography={geo}
                              onClick={() => {
                                onStateHover(geo.rsmKey)
                              }}
                              onMouseEnter={() => {
                                onStateHover(geo.rsmKey)
                              }}
                              onMouseLeave={() => {
                                onStateHover(geo.rsmKey)
                              }}
                              style={{ 
                            default: {
                              fill: "#0d5669",
                              stroke: "#e4d7b7",
                              strokeWidth: 0.75,
                              outline: "none",
                            
                            },
                            pressed: {
                              fill: "#607D8B",
                              stroke: "#607D8B",
                              strokeWidth: 0.75,
                              outline: "none"
                            },
                            hover: {
                              fill: "#607D8B",
                              stroke: "#607D8B",
                              strokeWidth: 0.75,
                              outline: "none",


                            }
                          }}
                            />
                          ))}
                          {geographies.map(geo => {
                            const centroid = geoCentroid(geo);
                            const cur = allStates.find(s => s.val === geo.id);
                            return (
                              <g key={geo.rsmKey + "-name"}>
                                {cur &&
                                  centroid[0] > -160 &&
                                  centroid[0] < -67 &&
                                  (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                                    <Marker coordinates={centroid}>
                                      <text y="2" fontSize={8} textAnchor="middle">
                                        {cur.id}
                                      </text>
                                    </Marker>
                                  ) : (
                                    <Annotation
                                      subject={centroid}
                                      dx={offsets[cur.id][0]}
                                      dy={offsets[cur.id][1]}
                                    >
                                      <text x={4} fontSize={8} alignmentBaseline="middle">
                                        {cur.id}
                                      </text>
                                    </Annotation>
                                  ))}
                              </g>

                            );
                          })}
                        </>
                      )}
                    </Geographies>

                  </ComposableMap>


                  <ReactTooltip 
                      type={'light'} 
                      delayShow={300} 
                      clickable={true} 
                      offset={{top: -5, bottom: 5, left: -5, right: 5}}
                      border={true}
                      borderColor={'brown'}>
                      {content}
                  </ReactTooltip>

                </div>

                {/* End MAP Wrapper */}
          

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </React.Fragment>

        )
    }

export default Map;