import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive.js";
import Pagination from "../elements/common/Pagination";
import FooterTwo from "../component/footer/FooterTwo";
import ReactTooltip from 'react-tooltip';

class News extends Component{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    constructor () {
        super()
        this.state = {
          isOpen: false,
          currentPage: 2
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='News' />
                {/* End Pagehelmet  */}

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'News'}   />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">

                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">

                                    {/* Start Single Area */}

                                        <div className="row sercice-details-content pb--80 align-items-center">

                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <a href="https://www.scenic.org/blog/place-making-institute-cultivates-resources-for-smart-development/" target="_blank"  rel="noopener noreferrer">
                                                    <div className="photoHover">
                                                    <img className="w-100" src="/assets/images/news/news-04.jpg"  rel="noopener noreferrer" alt="Adventures of A Narrative Gardener Book Cover" />
                                                    Photo By: Mick Hales
                                                    </div>
                                                    </a>
                                                </div>
                                            </div>

                                        <div className="col-lg-6 col-12">
                                                <div className="details mt_md--3 mt_sm--30">
                                                    <h4 className="title" data-tip data-for="amazon">
                                                    <a href="https://www.scenic.org/blog/place-making-institute-cultivates-resources-for-smart-development/" target="_blank"  rel="noopener noreferrer">
                                                    Place Making Institute Cultivates Resources for Smart Development
                                                    </a>
                                                    <ReactTooltip id="amazon" place="bottom" effect="solid">
                                                        <img height='160' width='240' src='assets/images/nov9.png' alt="Adventures of A Narrative Gardener Book Cover" />
                                                    </ReactTooltip>
                                                    </h4>
                                                    <h3 className="title" style ={{fontFamily: 'raleway, sans-serif'}}>November 9th, 2020</h3>
                                                    <p>"When shaping the look and feel of their jurisdictions, city planning directors often wrestle with the conflicting interests of developers and preservationists. 
                                                    To empower planners in historic and scenic cities and towns to better balance these disparate viewpoints and priorities, preservation and planning experts Ronald Lee Fleming, 
                                                    FAICP, and Jeffrey Soule, FAICP, developed the Place Making Institute (PMI). The program was inspired by the Mayors’ Institute on City Design, which was established by 
                                                    Jaquelin Robertson, former dean of the University of Virginia School of Architecture, Adele Chatfield-Taylor,
                                                    former director of the Design Arts Program of the National Endowment for the Arts, and former Charleston mayor Joseph P. Riley."
                                                    </p>
                                                    <p>-Rebecca Aloisi</p>                      
                                                </div>
                                            </div>
                                        </div>
                                        <div className="divider" style={{width: '90%', marginLeft: '5%', marginBottom: '50px', borderBottomStyle: 'solid', borderColor: '#661B0B'}}>
                                        </div>
                                    {/* End Single Area */}


                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30" >
                                                    <h4 className="title" data-tip data-for="bloomberg" >
                                                    <a href="https://www.bloomberg.com/news/articles/2020-09-10/amid-newport-s-mansions-a-battle-over-development" target="_blank" rel="noopener noreferrer" >BloombergCityLab: "History and Gentrification Clash in a Gilded Age Resort"</a>
                                                    <ReactTooltip id="bloomberg" place="bottom" effect="solid">
                                                        <img height='160' width='240' src='assets/images/bloomberg.png' alt="Place Making Aerial View" />
                                                    </ReactTooltip>
                                                    </h4>
                                                    <h3 className="title" style ={{fontFamily: 'raleway, sans-serif'}}>September 10th, 2020</h3>
                                                    <p>"After a real estate project met opposition from affluent and low-income residents alike, Newport, Rhode Island, imposed a moratorium on new development."</p>
                                                    <p>-Alex Ulam. Bloomberg CityLab</p>
                                                    <p>“What they are proposing is a development that is in a parking desert with a couple of hotels that has no walkability and no linkage to one of the last pieces of green space in the neighborhood. There is no identity or urban design that would reinforce the livability.”</p>
                                                    <p>-Ronald Lee Fleming</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb" style={{ fontSize: '1em', fontFamily: 'raleway, sans-serif', color: '#000000'}}>
                                                    <img className="w-100" src="/assets/images/news/news-01.jpg" alt="Place Making Aerial View"/>
                                                    Photo By: Robert Kranz
                                                </div>
                                            </div>
                                        </div>

                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="row mt--20">
                            <div className="col-lg-12">
                                {/* Start Pagination Area */}
                                <Pagination number1={false}  number2={true} number3={false}/>
                                {/* End Pagination Area */}
                            </div>
                        </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </React.Fragment>
        )
    }
}


export default News;
