import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import Header from "../component/header/HeaderFive.js";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import { Link } from 'react-router-dom';

const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--4',
        category: '',
        title: 'The Place Making Institute',
        description: 'Community-based design and planning',
        photocred: 'Warren Jagger',
        buttonText: 'Find out More',
        buttonLink: '/about'

    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--2',
        category: '',
        title: 'Bellevue House',
        description: 'Newport, Rhode Island',
        photocred: 'Warren Jagger',
        buttonText: 'bellevue page',
        buttonLink: '/bellevuehouse'
    },

]


const news = [
    {
        image: '01',
        title: 'BloombergCityLab: "History and Gentrification Clash in a Gilded Age Resort"',
        description: 'A moratorium on new development in Newport, Rhode Island',
        pagination: '/news',
        reference: '',
    },
    {

        image: '03',
        title: 'First Session',
        description: 'The first Place Making Institute at the Library on the grounds of Bellevue House. ',
        pagination: '2',
        reference: '/news',
    },
    {
        image: '02',
        title: 'Book Release',
        description: 'The Adventures of a Narrative Gardener: Creating a Landscape of Memory',
        pagination: '',
        reference: 'https://www.amazon.com/Adventures-Narrative-Gardener-Creating-Landscape/dp/1911282743',
    },

]

class CorporateBusiness extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){

        return(
            <Fragment>
                <Helmet pageTitle=" The Place Making Institute" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-dark" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title" style={{textShadow: "0 2px 0 rgba(0, 0, 0, 0.8)"}}>{value.title}</h1> : ''}
                                                    {value.description ? <p className="description" style={{textShadow: "0 2px 0 rgba(0, 0, 0, 0.8)", width: '70%', lineHeight: '30px'}}>{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="photocred" style={{ fontFamily: 'raleway, sans-serif'}}>
                                        <a>Photo By: {value.photocred}</a>
                                    </div>
                                </div>
                            ))}

                        </Slider>
                       
                    </div>
                </div>
                {/* End Slider Area   */}


                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title"><em>About</em></h2>
                                        <p className="description">The Place Making Institute (PMI) serves to strengthen the capacity of planners 
                                        and community leaders in historic/scenic cities and towns threatened by developer-influenced zoning boards.
                                          In addition research will be sponsored through a fellows program that houses two to four planners in 
                                          residence annually, taking advantage of the newly completed library which contains over 5000 publications. 
                                          One of the key aspects of the PMI is managing the integrity of the community in its public space, 
                                          architecture, civic institutions and design standards. The institute encourages the special character of places and 
                                          focuses on enhancing the skills and techniques needed to sustain and apply them nationwide.</p>
                                        <div><a className="rn-button-style--2 btn-solid" href='/about'> find out more</a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail position-relative" style={{ fontFamily: 'raleway, sans-serif', fontSize: '14px'}}>
                                    <img className="w-100" src="/assets/images/about/redBridge.jpg" alt="About Images"/>
                                    Photo By: Robert Kranz
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start News Area  */}
                <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">

                            {/* Start News Left Descript  */}
                            <div className="col-lg-3 col-md-6 col-12 mt--30" style={{paddingLeft: '20px' }}>
                                <div className="section-title">
                                    <h2 className="title"><em>News</em></h2>
                                    <p>Check out whats happening with the Place Making Institute!</p>
                                    <div className="service-btn">
                                        <a className="btn-transparent rn-btn-dark" href="/news"><Link to="/news">Go to the news page!</Link></a>
                                    </div>
                                </div>
                            </div>
                            {/* End News Left Descript  */}

                            {/* Start News Singles */}
                            <div className="col-lg-9">
                                <div className="row">
                                    {news.map((value , index) => (
                                        <div className="col-lg-4 col-md-4 mt--30" key={index}>
                                            <div className="standard-service">
                                                <div className="thumbnai">
                                                    <a href={value.reference} target="_blank" rel="noopener noreferrer">
                                                    <img src={`/assets/images/news/news-${value.image}.jpg`} alt="Corporate Images"/>
                                                    </a>
                                                </div>
                                                <div className="content">
                                                    <h3><a href="/news">{value.title}</a></h3>
                                                    <p>{value.description}</p>
                                                    <a className="btn-transparent rn-btn-dark" href="/news"><span className="text">Read More</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            {/* End News Singles  */}

                        </div>
                    </div>
                </div>
                {/* End Featured Service Area  */}

                {/* Start Counterup Area */}
                <div className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white" data-black-overlay="1" href='/map'>
                    <div className="container">
                       <Link to="/map"><CounterOne /></Link>
                    </div>
                </div>
                <div className="photocred bg_color--5" style={{paddingLeft: '1%', fontSize: '14px', fontFamily: 'raleway, sans-serif', color: '#000000',}}>
                    <a>Photo By: Robert Kranz</a>
                </div>
                {/* End Counterup Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-4.jpg" alt="Map"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner" >
                                    <div className="section-title">
                                        <h2 className="title">Place Making Locations</h2>
                                        
                                    </div>
                                    <div className="about-button mt--50">
                                        <a className="rn-button-style--2 btn-solid" href="/map">Explore Our Location Map</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;
